import React, { FC } from "react";
import { Tooltip } from "antd";
import moment from "moment";
import { isEmpty } from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import mediIntakeColors from "../../config/mediIntakeColors";
import { ICasesMedikationInTakeTooltip } from "../../models/components/ICasesMedikationInTakeTooltip";
import i18next from "i18next";

/**
 * ---
 * CasesMedikationInTakeTooltip
 * ---
 * CasesOverview need a bubble with tooltip information about medikation in take preview
 * @property {module:patientmanagement} patientmanagement
 * @class patientmanagement.components/CasesMedikationInTakeTooltip
 * @type {React.FC<ICasesMedikationInTakeTooltip>}
 * @return {React.ReactElement}
 */
const CasesMedikationInTakeTooltip: FC<ICasesMedikationInTakeTooltip> = ({
  adherence,
  isPast24Hours,
}): JSX.Element => {
  const { t } = useTranslation();
  const { status, statusReason, exactDateTimeAt } =
    !isEmpty(adherence) && adherence[0];
  const reason = !isEmpty(statusReason) ? ", " + statusReason : ""; //t(`casestable:adherence.${statusReason}`)
  const dateFormat: string =
    i18next.language === "de" ? "DD.MM.YYYY HH:mm" : "YYYY-MM-DD HH:mm";
  const time = !isEmpty(exactDateTimeAt)
    ? ", " + moment(exactDateTimeAt).format(dateFormat)
    : "";
  const showWarning = isPast24Hours(exactDateTimeAt);

  return (
    <>
      {!isEmpty(adherence) ? (
        <>
          <Tooltip
            placement="bottom"
            title={t(`casestable:adherence.${status}`) + reason + time}
          >
            <span
              style={{
                color: mediIntakeColors(status),
              }}
              className="comjoo-icon-pil"
            ></span>
          </Tooltip>
          {showWarning && (
            <Tooltip
              placement="bottom"
              title={t(`casestable:adherence.noCurrentData`)}
            >
              <ExclamationCircleOutlined className="warning-icon" />
            </Tooltip>
          )}
        </>
      ) : (
        <Tooltip placement="bottom" title={t(`casestable:adherence.noData`)}>
          <ExclamationCircleOutlined className="error" />
        </Tooltip>
      )}
    </>
  );
};

export default CasesMedikationInTakeTooltip;
