/**
 * @memberof patientmanagement
 * @interface IMediIntakeColors
 */
export type IMediIntakeColors = {
  completed: string;
  "entered-in-error": string;
  stopped: string;
  empty: string;
};

/**
 * @function patientmanagement#config/mediIntakeColors
 * @param { string | number} key
 * @returns
 */
const mediIntakeColors = (key: string | number = "empty"): string => {
  const mediIntakeColors: IMediIntakeColors | any = {
    completed: "#7d7d7d",
    "entered-in-error": "#7d7d7d",
    stopped: "#F76A6A",
    empty: "",
  };

  return mediIntakeColors[key];
};

export default mediIntakeColors;
