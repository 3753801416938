import React, { FC } from "react";
import { Avatar, Typography } from "antd";
import moment from "moment";
import i18next from "i18next";
import { ICasesAvatar } from "../../models/components/ICasesAvatar";

const { Text } = Typography;

/**
 * ---
 * CasesAvatar
 * ---
 * Cases in the summary column have an avatar with the patient's firstname,lastname and birthday.
 * This component format with styles the column
 * @class patientmanagement.components/CasesAvatar
 * @type {React.FC<ICasesAvatar>}
 * @return {React.ReactElement}
 */
const CasesAvatar: FC<ICasesAvatar> = ({ entry }): JSX.Element => {
  const dateFormat: string =
    i18next.language === "de" ? "DD.MM.YYYY" : "YYYY-MM-DD";
  return (
    <div
      style={{
        width: 100,
        minWidth: "100%",
        overflow: "hidden",
      }}
    >
      <Avatar
        size={40}
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "left",
          float: "left",
        }}
      >
        {entry?.patientFirstName?.charAt(0) + entry?.patientLastName?.charAt(0)}
      </Avatar>
      <p
        className="dtb-username"
        style={{
          marginLeft: 10,
          height: 40,
          marginBottom: 0,
          display: "inline-block",
          textAlign: "left",
          float: "left",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: "calc(100% - 60px)",
        }}
      >
        <span
          style={{
            width: "100%",
            display: "inline-block",
            textAlign: "left",
            float: "left",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {entry?.patientLastName}, {entry?.patientFirstName}
        </span>
        <br /> {moment(entry?.patientBirthdayString).format(dateFormat)}
      </p>{" "}
    </div>
  );
};

export default CasesAvatar;
