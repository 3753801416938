import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { Layout } from "antd";
import { IPatientManagement } from "./models/IPatientManagement";
import CasesOverview from "./pages/CasesOverview/CasesOverview";
import CasesArchivOverview from "./pages/CasesArchiveOverview/CasesArchivOverview";
import SidebarMenu from "./components/SidebarMenu/SidebarMenu";
import CaseDetail from "./pages/CaseDetail/CaseDetail";
import patientEN from "./locale/en/patient";
import patientDE from "./locale/de/patient";
import therapyEN from "./locale/en/therapy";
import therapyDE from "./locale/de/therapy";
import statuscodesEN from "./locale/en/statuscodes";
import statuscodesDE from "./locale/de/statuscodes";
import tabsEN from "./locale/en/tabs";
import tabsDE from "./locale/de/tabs";
import actionsEN from "./locale/en/actions";
import actionsDE from "./locale/de/actions";
import casestableEN from "./locale/en/casestable";
import casestableDE from "./locale/de/casestable";
import caseSidebarEN from "./locale/en/sidebar";
import caseSidebarDE from "./locale/de/sidebar";
import pmNavEN from "./locale/en/nav";
import pmNavDE from "./locale/de/nav";

i18next.addResourceBundle("en", "patient", patientEN);
i18next.addResourceBundle("de", "patient", patientDE);
i18next.addResourceBundle("en", "therapy", therapyEN);
i18next.addResourceBundle("de", "therapy", therapyDE);
i18next.addResourceBundle("en", "statuscodes", statuscodesEN);
i18next.addResourceBundle("de", "statuscodes", statuscodesDE);
i18next.addResourceBundle("en", "tabs", tabsEN);
i18next.addResourceBundle("de", "tabs", tabsDE);
i18next.addResourceBundle("en", "actions", actionsEN);
i18next.addResourceBundle("de", "actions", actionsDE);
i18next.addResourceBundle("en", "casestable", casestableEN);
i18next.addResourceBundle("de", "casestable", casestableDE);
i18next.addResourceBundle("en", "caseSidebar", caseSidebarEN);
i18next.addResourceBundle("de", "caseSidebar", caseSidebarDE);
i18next.addResourceBundle("en", "pm-nav", pmNavEN);
i18next.addResourceBundle("de", "pm-nav", pmNavDE);

const { Content } = Layout;

/**
 * * Returns entrypoint PatientManagement element.
 * @function PatientManagement
 * @module /patientmanagement
 * @namespace patientmanagement
 * @type {React.FC<IPatientManagement>}
 * @returns {React.ReactElement}
 *
 */
const PatientManagement = ({
  detailInfoVisible,
  onCloseDetail,
}: IPatientManagement): JSX.Element => {
  const navigate = useNavigate();

  /**
   *
   * @description navigate to link
   * @param param0
   */
  const navigateToLink = ({
    caseId,
    patientId,
    archive = false,
  }: {
    caseId: string;
    patientId: string;
    archive: boolean;
  }): void => {
    let path: string = "";
    if (!archive) {
      path = `/patient/${patientId}/case/${caseId}/monitoring`;
    } else {
      path = `/archive/patient/${patientId}/case/${caseId}/monitoring`;
    }
    navigate(path);
  };

  return (
    <Layout className="dtb-page-layout-with-nav">
      <div className="dtb-page-content-layout">
        <div className="dtb-page-content-layout-nav">
          <Content className="dtb-inner-content-nav">
            <SidebarMenu />
          </Content>
        </div>

        <div className="dtb-page-content">
          <Content className="dtb-main-content-wrapper">
            <Routes>
              <Route
                key="1"
                path="/archive"
                element={
                  <CasesArchivOverview navigateToLink={navigateToLink} />
                }
              />
              <Route
                key="2"
                path="/cases"
                element={<CasesOverview navigateToLink={navigateToLink} />}
              />
              <Route
                key="3"
                path="/patient/:patientId/case/:caseId/:tab/*"
                element={
                  <CaseDetail
                    detailInfoVisible={detailInfoVisible}
                    onCloseDetail={onCloseDetail}
                  />
                }
              />
              <Route
                key="4"
                path="/archive/patient/:patientId/case/:caseId/:tab/*"
                element={
                  <CaseDetail
                    detailInfoVisible={detailInfoVisible}
                    onCloseDetail={onCloseDetail}
                  />
                }
              />
              <Route
                key="5"
                path="/"
                element={<CasesOverview navigateToLink={navigateToLink} />}
              />
            </Routes>
          </Content>
        </div>
      </div>
    </Layout>
  );
};

export default PatientManagement;
