import React, { FC } from "react";
import { Col, Row, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { IPatientSkeleton } from "../../models/components/IPatientSkeleton";

/**
 * ---
 * PatientSkeleton
 * ---
 * @class patientmanagement.components/PatientSkeleton
 * @type {React.FC<IPatientSkeleton>}
 * @return {React.ReactElement}
 */
const PatientSkeleton: FC<IPatientSkeleton> = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={16}>
        <fieldset>
          <legend>{t("patient:patient")}</legend>
          <Skeleton title={false} paragraph={{ rows: 3 }} />
        </fieldset>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={16}>
        <fieldset>
          <legend>{t("patient:contact")}</legend>
          <Skeleton title={false} paragraph={{ rows: 3 }} />
        </fieldset>
        <Skeleton title={false} paragraph={{ rows: 3 }} />
      </Col>
    </Row>
  );
};

export default PatientSkeleton;
