import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Row, Col, Form, Button, message, Tooltip } from "antd";
import "./../../scss/Patient.scss";
import FormBuilder from "antd-form-builder";
import { useTranslation } from "react-i18next";
import patientTemplate from "../../templates/forms/patientTemplate";
import patientInsuranceTemplate from "../../templates/forms/patientInsuranceTemplate";
import patientContactTemplate from "../../templates/forms/patientContact";
import patientAddressTemplate from "../../templates/forms/patientAddressTemplate";
import i18next from "i18next";
import en from "i18n-iso-countries/langs/en.json";
import de from "i18n-iso-countries/langs/de.json";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { ConfigProvider } from "antd";
import deDe from "antd/lib/locale/de_DE";
import { useParams } from "react-router-dom";
import PatientSkeleton from "../PatientSkeleton/PatientSkeleton";
import settings from "../../config/settings";
import { IPatientForm } from "../../models/components/IPatientForm";

/**
 * ---
 * PatientForm
 * ---
 * Compoent PatientForm include antd-form-builder package and create
 * form template with patient information view and editable mode
 * - patientTemplate
 * - patientInsuranceTemplate (is disabled)
 * - patientContactTemplate
 * - patientAddressTemplate (is disabled)
 * @class patientmanagement.components/PatientForm
 * @type {React.FC<IPatientForm>}
 * @return {React.ReactElement}
 */
const PatientForm = ({ patientCareStore }: IPatientForm): JSX.Element => {
  const { selectedCase, patient } = patientCareStore;
  const { patientId } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [viewMode, setViewMode] = useState(true);
  const [minAnimation, setMinAnimation] = useState(true);
  const [requiredInsuranceNumber, setRequiredInsuranceNumber] = useState(true);

  let langCountries: any =
    i18next.language === "de"
      ? [{ value: "Deutschland", label: "Deutschland" }]
      : [{ value: "Deutschland", label: "Germany" }];
  const dateFormat: string =
    i18next.language === "de" ? "DD.MM.YYYY" : "YYYY-MM-DD";

  useEffect(() => {
    getPatient();
    setTimeout(() => {
      setMinAnimation(false);
    }, settings.minAnimation);
  });

  /**
   * @function patientmanagement.components/PatientForm.getPatient
   */
  const getPatient = async () => {
    await patientCareStore.fetchPatientData({ patientId });
  };

  /**
   * @function patientmanagement.components/PatientForm.handleFinish
   */
  const handleFinish = async (values: any) => {
    const data = await form.validateFields();

    if (!data?.hasOwnProperty("errorFields")) {
      const res = await patientCareStore.patchPatientItem({
        patientId: patientId,
        contentValues: data,
      });
      if (res.status === 200) {
        message.success("Daten erfolgreich gespeichert.");
        setViewMode(true);
      }

      if (res.status === 401) {
        message.error(
          "Daten konnten nicht gespeichert werden, da Sie keine Berechtigung haben."
        );
        setViewMode(false);
      }
    }
  };

  /**
   * @function patientmanagement.components/PatientForm.cancelEdit
   */
  const cancelEdit = () => {
    form.resetFields();
    setViewMode(true);
    setMinAnimation(true);
    setTimeout(() => {
      setMinAnimation(false);
    }, 3000);
  };

  /**
   * @description get patientTemplate
   * @function patientmanagement.components/PatientForm.patientTemplateConfig
   */
  let patientTemplateConfig: any = patientTemplate(
    t,
    selectedCase,
    viewMode,
    dateFormat
  );

  /**
   * @description  get patientContactTemplate
   * @function @function patientmanagement.components/PatientForm.patientContactTemplateConfig
   */
  let patientContactTemplateConfig: any =
    patient !== null
      ? patientContactTemplate(t, patient, selectedCase, viewMode)
      : {};

  /**
   * @description  get patientInsuranceTemplate
   */
  let patientInsuranceTemplateConfig: any = patientInsuranceTemplate({
    t,
    patient,
    viewMode,
    form,
    requiredInsuranceNumber,
    setRequiredInsuranceNumber,
  });

  /**
   * @description  get patientAddressTemplate
   * @function PatientForm#patientAddressTemplateConfig
   */
  let patientAddressTemplateConfig: any = patientAddressTemplate(
    t,
    langCountries,
    patient,
    viewMode
  );

  /**
   * @description fallback show PatientSkeleton
   */
  if (!patientCareStore.patientLoaded || minAnimation) {
    return <PatientSkeleton />;
  }

  return (
    <ConfigProvider locale={deDe}>
      <Form
        form={form}
        layout={/*viewMode ? 'horizontal' : 'vertical'*/ "vertical"}
        onFinish={handleFinish}
        className={"dtb-patient-form"}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{ float: "right" }} className="dtb-edits">
              {viewMode ? (
                <Tooltip placement="topLeft" title={t("patient:edit")}>
                  <Button
                    className="dtb-edit-patient"
                    onClick={() => setViewMode(!viewMode)}
                  >
                    <EditOutlined type="primary" />
                  </Button>
                </Tooltip>
              ) : (
                <>
                  <Tooltip placement="topLeft" title={t("patient:save")}>
                    <Button onClick={handleFinish}>
                      <CheckOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={t("patient:cancel")}>
                    <Button htmlType="submit" onClick={cancelEdit}>
                      <CloseOutlined />
                    </Button>
                  </Tooltip>
                </>
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            {selectedCase !== null && !minAnimation && (
              <>
                <FormBuilder
                  meta={patientTemplateConfig}
                  form={form}
                  viewMode={viewMode}
                />
              </>
            )}
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                {selectedCase !== null && !minAnimation && (
                  <>
                    <FormBuilder
                      meta={patientContactTemplateConfig}
                      form={form}
                      viewMode={viewMode}
                    />
                  </>
                )}
              </Col>
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                {selectedCase !== null && (
                  <>
                    <FormBuilder
                      meta={patientAddressTemplateConfig}
                      form={form}
                      viewMode={viewMode}
                    />
                  </>
                )}
              </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={8}></Col>*/}
            </Row>
          </Col>

          {/** <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            {selectedCase !== null && (
              <>
                <FormBuilder
                  meta={patientInsuranceTemplateConfig}
                  form={form}
                  viewMode={viewMode}
                />
              </>
            )}
          </Col>
           */}
          {!viewMode && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "right" }}
            >
              <Button htmlType="submit" type="primary">
                <CheckOutlined />
                {t("patient:save")}
              </Button>
              <Button onClick={cancelEdit} style={{ marginLeft: 10 }}>
                <CloseOutlined />
                {t("patient:cancel")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </ConfigProvider>
  );
};

export default observer(PatientForm);
