import React, { FC } from "react";
import { isEmpty, isNil } from "lodash";
import { useTranslation } from "react-i18next";
import { IPatientBodyDetails } from "../../models/components/IPatientBodyDetails";
import {
  IMedplan,
  IUnderlyingPatientInformation,
} from "../../models/data/IMedplan";
import { Card, Descriptions, Skeleton, Typography } from "antd";

const { Text } = Typography;
const { Item } = Descriptions;

/**
 * ---
 * PatientBodyDetails
 * ---
 *
 * This component show card of patientt body details
 * - showSkeleton show skeleton
 * - show patient information bodyHeight
 * - show patient information bodyWeight
 * - show patient information bodySurface
 * @class patientmanagement.components/PatientBodyDetails
 * @type {React.FC<IPatientBodyDetails>}
 * @return {React.ReactElement}
 */
const PatientBodyDetails: FC<IPatientBodyDetails> = ({
  medplan,
  showSkeleton,
}): JSX.Element => {
  const { t } = useTranslation();
  const { underlyingPatientInformation }: any = !isEmpty(medplan) && medplan;
  const { height, weight, bodySurface }: IUnderlyingPatientInformation =
    !isEmpty(underlyingPatientInformation) && underlyingPatientInformation;

  return (
    <Card
      size="small"
      title={t("caseSidebar:patientInformation")}
      id="dtb-sidebar-patientinfo"
    >
      {showSkeleton ? (
        <>
          <Skeleton
            title={false}
            paragraph={{ rows: 3 }}
            style={{ margin: 0, padding: 0, marginTop: 10 }}
          />
        </>
      ) : (
        <>
          <Descriptions
            style={{ display: "flex", flexDirection: "row" }}
            className="dtb-select-medplan"
          >
            <Item
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
              label={t("patient:bodyHeight")}
            >
              {(!isNil(height) && height + " cm") || "-"}
            </Item>
          </Descriptions>

          <Descriptions
            style={{ display: "flex", flexDirection: "row" }}
            className="dtb-select-medplan"
          >
            <Item
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
              label={t("patient:bodyWeight")}
            >
              {(!isNil(weight) && weight + " kg") || "-"}
            </Item>
          </Descriptions>

          <Descriptions className="dtb-select-medplan">
            <Item
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
              label={t("patient:bodySurface")}
              //className="dtb-selected-medplan"
            >
              {(!isNil(bodySurface) && bodySurface + " qm") || "-"}
            </Item>
          </Descriptions>
        </>
      )}
    </Card>
  );
};

export default PatientBodyDetails;
