import { action, computed, makeAutoObservable, observable } from 'mobx';
import keycloak from '../../keycloak';
import type IUser from './models/IUser';
import type IUserAttributes from './models/IUserAttributes';

/**
 * MobX AuthStore
 * @description
 * - 
 * constructor 
 * - init keycloak package and create an instace
 * - create interval to check updateToken all 18sec
 * @property {null | boolean} authenticated - is authenticated
 * @property {null | string} username - username
 * @property {IUser} user - user infos
 * @property {IUserAttributes} userAttributes - user attributes
 */
export class AuthStore {
  @observable
  private authenticated: null | boolean = null;
  @observable
  private username: null | string = null;
  @observable
  private user: IUser = {
    username: '',
    firstName: '',
    lastName: ''
  };
  @observable
  private userAttributes: IUserAttributes = {
    isDoctor: false,
    isHome: false,
    isPlanner: false,
    isMfa: false
  };

  constructor() {
    makeAutoObservable(this);
    /**
     * @description init sso
     */
    if (!keycloak.authenticated) {
      this.initTest();
    }
    setInterval(async () => {
      await this.updateToken();
    }, 180000);
  }

  /**
   * @method AuthStore.getAuthenticated
   * @description getter is authenticated
   * @returns {boolean}
   */
  @computed
  public get getAuthenticated(): boolean | null {
    return this.authenticated;
  }

  /**
   * @method AuthStore.getter/getUserAttributes
   * @description getter userAttributes
   * @returns {IUserAttributes}
   */
  @computed
  public get getUserAttributes(): IUserAttributes {
    return this.userAttributes;
  }

  /**
   * @method AuthStore.getter/getUser
   * @returns {IUser}
   */
  @computed
  public get getUser(): IUser {
    return this.user;
  }

  /**
   * @method AuthStore.initTest
   * @description init keycloak auth check
   */
  @action
  private async initTest(): Promise<any> {
    try {
      const check = await keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false
      });
      this.authenticated = check;
      if (check) {
        await this.getProfileData();
      }
    } catch (e) {}
  }

  /**
   * @method AuthStore.getProfileData
   * @description get user profil
   * - set config user attributes default or is set
   */
  @action
  public getProfileData = async (): Promise<any> => {
    try {
      const profil: any = await keycloak.loadUserProfile();
      this.username = profil.username;
      this.user = {
        id: profil.id,
        username: profil.username,
        firstName: profil.firstName,
        lastName: profil.lastName,
        email: profil.email,
        emailVerified: profil.emailVerified
      };

      this.userAttributes = {
        isDoctor: profil.attributes.hasOwnProperty('isDoctor')
          ? profil?.attributes?.isDoctor?.[0] === 'true'
            ? true
            : false
          : false,
        isHome: profil.attributes.hasOwnProperty('isHome')
          ? profil?.attributes?.isHome?.[0] === 'true'
            ? true
            : false
          : false,
        isPlanner: profil.attributes.hasOwnProperty('isPlanner')
          ? profil?.attributes?.isPlanner?.[0] === 'true'
            ? true
            : false
          : true,
        isMfa: profil.attributes.hasOwnProperty("isMFA")
          ? profil?.attributes?.isMFA?.[0] === "true"
            ? true
            : false
          : false,
      };
    } catch (e) {}
  };

  /**
   * @method AuthStore.updateToken
   * @description updtate keycloak token
   */
  @action
  public updateToken = async (): Promise<any> => {
    await keycloak.updateToken(180);
  };

  /**
   * @method AuthStore.updateProfil
   * @param {any} profil 
   * @deprecated
   */
  @action
  public updateProfil = (profil: any): void => {
    // 'TODO: need user api endpoint info'
    //  need user api
  };

  /**
   * @method AuthStore.goToProfil
   * @description fetch account
   */
  @action
  public goToProfil = async (): Promise<any> => {
    await keycloak.accountManagement();
  };

  /**
   * @method AuthStore.logout
   * @description fetch logout keycloak
   */
  public logout = async (): Promise<any> => {
    keycloak.logout();
  };
}
