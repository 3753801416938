/**
 * @memberof patientmanagement
 * @description patientmanagement#config/settings obj
 */
const settings = {
  minAnimation: 800,
  casesTableHeight: 148,
  casesAnimation: {
    counterSkeleton: 1,
    spinner: false,
  },
};

export default settings;
