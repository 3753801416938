import React, { FC } from "react";
import { Badge, Tooltip, Typography } from "antd";
import CasesTooltipSideffectsListThresholdLamp from "../CasesTooltipSideffectsListThresholdLamp/CasesTooltipSideffectsListThresholdLamp";
import trafficLightThresholdColors from "../../config/trafficLightThresholdColors";
import { isEmpty } from "lodash";
import { ICasesSideEffectsThresholdTooltip } from "../../models/components/ICasesSideEffectsThresholdTooltip";

const { Text } = Typography;

/**
 * ---
 * CasesSideEffectsThresholdTooltip
 * ---
 * CasesOverview need in column a bubble with highest value from a sideeeffect list with
 * tooltip list of last entry day a highest value from sideeffects
 * @class patientmanagement.components/CasesSideEffectsThresholdTooltip
 * @type {React.FC<ICasesSideEffectsThresholdTooltip>}
 * @return {React.ReactElement}
 */
const CasesSideEffectsThresholdTooltip: FC<ICasesSideEffectsThresholdTooltip> = ({
  alarmThresholdReview,
}): JSX.Element => {
  if (alarmThresholdReview === undefined) {
    return <></>;
  }
  /**
   * @description get traffic light config
   */
  const teestt = alarmThresholdReview?.map((item: any) => {
    return item?.values?.[0];
  });

  const highestValue =
    teestt !== undefined
      ? Math.max(...teestt.filter((item: any) => item !== "-1"))
      : null;
  const fu =
    highestValue === -Infinity || highestValue === NaN || highestValue === null
      ? "-1"
      : highestValue;

  const bg = trafficLightThresholdColors(fu);
  /**
   * @description change color in different alaram threshold
   */
  const colorText =
    (alarmThresholdReview && alarmThresholdReview?.[0]?.values?.[0] < 1) ||
    alarmThresholdReview?.[0]?.values?.[0] > 2
      ? "#333"
      : "#333";

  const toolTipCase = CasesTooltipSideffectsListThresholdLamp(
    alarmThresholdReview
  );

  return (
    <Tooltip
      overlayClassName="tool-tip-item side-effect"
      placement="bottomLeft"
      color="white"
      title={toolTipCase}
    >
      <div
        style={{
          width: 40,
          height: 40,
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Badge
          count={
            alarmThresholdReview !== undefined
              ? alarmThresholdReview?.length
              : ""
          }
          //className={highestValue === undefined ? 'badge none' : ''}
          style={{
            border: "solid 1px rgba(49,50,55,.5)",
            cursor: "pointer",
            color: colorText, //amountFontColor
            backgroundColor: bg || "transparent",
            lineHeight: "1.5",
          }}
          title=" " // this is needed to avoid double tooltip
        />
      </div>
    </Tooltip>
  );
};

export default CasesSideEffectsThresholdTooltip;
