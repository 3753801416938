import React, { FC } from "react";
import { Layout } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ICaseDetailSidebar } from "../../models/components/ICaseDetailSidebar";
import PatientBodyDetails from "../PatientBodyDetails/PatientBodyDetails";
import TherapyDetails from "../TherapyDetails/TherapyDetails";
import CaseTreatment from "../CaseTreatment/CaseTreatment";
import CaseCardPatient from "../CaseCardPatient/CaseCardPatient";
// import DiagnoseDetails from '../DiagnoseDetails/DiagnoseDetails';

const { Content } = Layout;

/**
 * ---
 * CaseDetailSidebar
 * ---
 * wrap all sidebar box information
 *
 * #### include Components
 * - card CaseCardPatient (only mobil)
 * - card CaseTreatment (only mobil)
 * - link planer button style
 * - card TherapyDetails: therapy plan details
 * - card PatientBodyDetails: patient body details information
 * @class patientmanagement.components/CaseDetailSidebar
 * @type {React.FC<ICaseDetailSidebar>}
 * @return {React.ReactElement}
 */
const CaseDetailSidebar: FC<ICaseDetailSidebar> = ({
  organisationManagementStore,
  medicationTherapyStore,
  patientCareStore,
  userAttributes,
  showSkeleton,
}): JSX.Element => {
  const { t } = useTranslation();
  const { patientId } = useParams();

  const orgConfig = organisationManagementStore.getOrgConfig;
  // current medplan details
  const currentMedplan = medicationTherapyStore.getCurrentMedplan;
  return (
    <Content className="dtb-sidebar dtb-case-sidebar">
      <div className="dtb-scroll-content">
        {orgConfig !== null && userAttributes.isPlanner && (
          <a
            href={orgConfig?.planerPatientPage.replace(
              "{patientId}",
              "" + patientId
            )}
            type="primary"
            style={{
              margin: "0px 0px 14px 0px",
              width: "100%",
              boxSizing: "border-box",
            }}
            className="ant-btn ant-btn-primary"
          >
            {t("caseSidebar:patientPlaner")}
          </a>
        )}

        <CaseCardPatient
          patientCareStore={patientCareStore}
          showSkeleton={showSkeleton}
          size={"small"}
        />

        <CaseTreatment
          patientCareStore={patientCareStore}
          showSkeleton={showSkeleton}
          size={"small"}
        />

        {/*
        <DiagnoseDetails
          showSkeleton={showSkeleton}
          size={'small'}
          //therapyStore={therapyStore}
        />
        */}

        <TherapyDetails
          medicationTherapyStore={medicationTherapyStore}
          currentMedplan={currentMedplan}
          showSkeleton={showSkeleton}
        />

        <PatientBodyDetails
          medplan={currentMedplan}
          showSkeleton={showSkeleton}
        />
      </div>
    </Content>
  );
};

export default observer(CaseDetailSidebar);
